<template>
	<el-form class="page" :model="ceneDani.parametri" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<forma-cena v-model="ceneDani.parametri" :za-dan="true"></forma-cena>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button :disabled="onSubmitLoading || $utils('loIsEqual', ceneDaniOriginal, ceneDani)" v-loading="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'cene-dani-edit',
	data() {
		return {
			dialogVisible: false,
			onSubmitLoading: false,
			fullscreenLoading: true,
			id: '',
			datum: '',
			idCena: '',
			pravac: '',
			formCreate: '',
			ceneDaniOriginal: {},
			ceneDani: {
				id_cene_parametri: null,
				datum: new Date(),
				pravac: 'polazak',
				parametri: {
					naziv_cenovnika: '',
					id_linije: null,
					naziv_linije: '',
					datum_od: null,
					datum_do: null,
					dani_polazak: [],
					dani_povratak: [],
					vrsta_cene: 'fiksna', // enum
					cena_fiksna: null,
					cena_min: null,
					cena_max: null,
					min_cena_povratne: 0,
					koeficijent_povratne: null,
					id_model_obracuna: null,
					stopa_rasta: null,
					mnozilac: null,
					isplativost: null,
					id_valuta: null,
					broj_raspolozivih: null,
					last_minute: 'NE', // enum
					first_minute: null,
					last_minute_min_isplativosti: null,
					last_minute_max_isplativosti: null,
					last_minute_fiksni_popust: null,
					last_minute_procenat_popust: null,
					last_minute_fiksna_cena: null,
					koeficijenti_starosne_grupe: [],
					cene: [{
						cena: 0,
						raspolozivo: 1,
						iskorisceno: 0
					}]
				}
			},
			rules: {
				'datum_od': [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				'datum_do': [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				'naziv_cenovnika': [{ required: true, max: 250, trigger: 'blur', message: this.$t('message.obavezno') }],
				'id_linije': [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				'dani_polazak': [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				'dani_povratak': [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				'id_valuta': [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				'id_model_obracuna': [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				'broj_raspolozivih': [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				'stopa_rasta': [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }]
			}
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.datum = this.ceneDani.datum = this.$route.params.datum;
		this.idCena = this.ceneDani.id_cene_parametri = this.$route.params.idCena;
		this.pravac = this.ceneDani.pravac = this.$route.params.pravac;

		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('cene.cena') + ' \u2013 ' + this.pravac + ' \u2013 ' + (this.datum ? this.$utils('formatDate', this.datum) : '')
		});

		this.$utils('stopLoadingAfter', [this.getCene(), this.getStarosneGrupe()]);
	},
	methods: {
		getCene() {
			if (!this.formCreate) {
				this.$get('ceneDani', { idCeneDana: this.id, pravac: this.pravac }).then(data => {
					this.ceneDani = data;
					this.ceneDani.parametri.naziv = data.naziv;
					this.ceneDani.parametri.linija = data.linija;
					this.ceneDaniOriginal = _.cloneDeep(this.ceneDani);
				});
			}
			else {
				this.$get('cenovnik', { id: this.idCena }).then(data => {
					this.ceneDani.parametri = data;
					this.ceneDani.parametri.datum_od = this.datum;
					this.ceneDani.parametri.datum_do = this.datum;
					// Original
					this.ceneDaniOriginal.parametri = _.cloneDeep(this.ceneDani.parametri);
				});
			}
		},
		onSubmit() {
			if (this.ceneDani.last_minute === 'DA' && this.ceneDani.last_minute_max_isplativosti <= this.ceneDani.last_minute_min_isplativosti) {
				this.onSubmitLoading = false;
				this.$alert(this.$t('cene.lastMinuteIsplativostNijeValidna') + ' ' + this.ceneDani.last_minute_max_isplativosti, this.$t('confirm.upozorenje'), {
					confirmButtonText: this.$t('global.uredu')
				});
			}
			else {
				return this.$refs.form.validate().then(() => {
					this.onSubmitLoading = true;
					let data = JSON.stringify(this.ceneDani);

					if (this.formCreate)
						this.$save('ceneDani', data);
					else
						this.$update('ceneDani', { idCeneDana: this.id, pravac: this.pravac }, data);
				}).catch((err) => {
					this.onSubmitLoading = false;
					return err;
				});
			}
		},
		getStarosneGrupe() {
			return this.$get('starosneGrupe', { size: 2000 }).then(data => {
				let starosneGrupe = [];
				data.content.forEach((value) => {
					starosneGrupe.push({ 'id_starosne_grupe': value.id, 'naziv': value.naziv, 'koeficijent': 1, 'max_cena_povratne': 0 });
				});
				this.ceneDani.koeficijenti_starosne_grupe = _.merge(starosneGrupe, this.ceneDani.koeficijenti_starosne_grupe);
				this.ceneDani.koeficijenti_starosne_grupe.map(grupa => {
					if (grupa.naziv.toLowerCase().search('gratis') > -1) {
						grupa.koeficijent = 0;
						grupa.max_cena_povratne = 0;
					}
				});
			});
		},
		getOdrediCenu() {
			return this.$post('odrediCenu', JSON.stringify(this.ceneDani.parametri)).then(data => {
				let ukupno = 0;
				data.forEach(val => {
					ukupno = ukupno + val.cena * val.raspolozivo;
				});
				return ukupno;
			}).catch(() => {
				return 0;
			});
		}
	},
	components: {
		formaCena: require('../forma').default
	}
};
</script>
